import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import cn from 'classnames';

import styles from './styles.module.scss';
import SearchBarInput from './SearchBarInput';

import type { ISearchBoxTooltip } from '../config/declarations';

/* Main Component */
const SearchTooltip: FC<ISearchBoxTooltip> = ({
  tooltipRef,
  searchRef,
  searchValue,
  searchBox,
  psychicsList,
  clear,
  changeHandler,
}) => (
  <Tooltip
    ref={tooltipRef}
    delayShow={100}
    delayHide={100}
    noArrow
    openOnClick
    clickable
    closeEvents={{ blur: false, mouseleave: false }}
    className={cn(
      styles.tooltip,
      { [styles.tooltipPadding]: Boolean(searchValue) },
    )}
    place="bottom-start"
  >
    <SearchBarInput
      tooltipRef={tooltipRef}
      searchRef={searchRef}
      searchValue={searchValue}
      searchBox={searchBox}
      changeHandler={changeHandler}
      clear={clear}
      psychicsList={psychicsList}
      isTooltip
    />
  </Tooltip>
);

export default SearchTooltip;
