/* eslint-disable no-param-reassign */
import cookie from 'js-cookie';

import type { Link, Psychic } from 'src/__generated__/graphqlTypes';
import { NodeEnv } from 'constants/enums';
import { SORT_ID_COOKIE } from 'constants/constants';

import { ReducerStore } from '../declarations';

export const reducer = (children: Array<Psychic | Link> | undefined): any => {
  if (!children?.length) {
    return null;
  }

  return children?.reduce((store: ReducerStore, item: Link | Psychic): any => {
    if (item.__typename === 'Psychic') {
      store.psychicFrame = item;
    }

    if (item.__typename === 'Link' && item.slug === 'secondary-link') {
      store.secondaryButton = item;
    }

    if (item.__typename === 'Link' && item.slug === 'pagination-button') {
      store.paginationButton = item;
    }

    if (item.__typename === 'Link' && item.slug === 'pagination-back-button') {
      store.paginationBackButton = item;
    }

    if (item.__typename === 'Link' && item.slug === 'pagination-next-button') {
      store.paginationNextButton = item;
    }

    return store;
  }, {} as ReducerStore) || {};
};

export const setSortIdCookie = (updatedSortId: string) => {
  const currentDate = new Date();
  const expirationDate = new Date(currentDate.getTime() + 365 * 24 * 60 * 60 * 1000);
  const options = {
    secure: process.env.NODE_ENV === NodeEnv.PROD,
    path: '/',
    expires: expirationDate,
  };
  cookie.set(SORT_ID_COOKIE, updatedSortId, options);
};

export const getSortIdCookie = () => cookie.get(SORT_ID_COOKIE);
