import { FC } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IPsychicsList } from '../config/declarations';

export const PsychicsList: FC<IPsychicsList> = ({
  baseLink,
  psychicsList,
  isPsychicListVisible,
}) => {
  if (!psychicsList || psychicsList.length === 0 || !isPsychicListVisible) {
    return null;
  }

  return (
    <ul className={styles.list}>
      {psychicsList.map((psychic) => (
        <li
          key={psychic.extId}
          className={styles.listElement}
        >
          <DataLink
            href={`${baseLink}?search=${psychic.lineName}&extid=${psychic.extId}`}
            className={styles.listElementLink}
          >
            {psychic.lineName}
          </DataLink>
        </li>
      ))}
    </ul>
  );
};
